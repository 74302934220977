/** @jsx jsx */
import { jsx, Themed } from "theme-ui";
import { Fragment } from "react";
import ActivityGroupCardGrid from "../components/ActivityGroupCardGrid";

const Section = ({ title, groups }) => (
  <Fragment>
    <Themed.h2 sx={{ marginBottom: "30px" }}>{title}</Themed.h2>
    <ActivityGroupCardGrid activityGroups={groups} />
  </Fragment>
);

const ActivityGroupSections = ({ activityGroups }) => {
  const sections = [
    {
      title: "Pre-braille activity groups",
      groups: activityGroups.filter(group => group.level === "Pre-braille"),
    },
    {
      title: "Braille activity groups",
      groups: activityGroups.filter(group => group.level === "Braille"),
    },
  ];

  return (
    <Fragment>
      {sections.map(({ title, groups }, index) => (
        <div
          key={title}
          sx={{ marginBottom: index < sections.length - 1 ? "100px" : 0 }}
        >
          <Section title={title} groups={groups} />
        </div>
      ))}
    </Fragment>
  );
};

export default ActivityGroupSections;
