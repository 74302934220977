/** @jsx jsx */
import { jsx } from "theme-ui";
import Grid from "./Grid";
import Card from "./Card";
import ColorMap from "../utils/ColorMap";
import commentsIcon from "assets/icons/comments.svg";
import likeIcon from "assets/icons/like_red.svg";
import timeIcon from "assets/icons/time.svg";
import oneParticipantIcon from "assets/icons/participants_one.svg";
import multipleParticipantsIcon from "assets/icons/participants_multiple.svg";
import InvertableImage from "components/InvertableImage";

const CardGrid = ({
  items,
  style = {},
  cardTitleHeadingComponent,
  additionalCardStyle = {},
}) => (
  <Grid sx={style}>
    {items.map(
      ({
        key,
        title,
        body,
        footerLeft,
        footerRight,
        footerComponent,
        image,
        linkTo,
        color,
        backgroundColor,
      }) => (
        <Card
          key={key}
          title={title}
          body={body}
          image={image}
          footerLeft={footerLeft}
          footerRight={footerRight}
          footerComponent={footerComponent}
          linkTo={linkTo}
          color={color}
          backgroundColor={backgroundColor}
          TitleHeadingComponent={cardTitleHeadingComponent}
          style={additionalCardStyle}
        />
      )
    )}
  </Grid>
);

export default CardGrid;

const CardFooter = ({ comments, likes, numberOfParticipants, time }) => (
  <div
    sx={{
      display: "flex",
      padding: "1rem",
      fontWeight: 500,
      backgroundColor: "#F8F8F8",
      marginTop: "0.5rem",
    }}
  >
    <div sx={{ display: "flex", gap: 26 }}>
      <div sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
        <InvertableImage
          src={timeIcon}
          alt={`Activity time is ${time} minutes`}
          sx={{ display: "block", width: 20, height: "auto" }}
        />
        <span aria-hidden>{time}</span>
      </div>
      <div sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
        <InvertableImage
          src={
            numberOfParticipants === 1
              ? oneParticipantIcon
              : multipleParticipantsIcon
          }
          alt={`Activity is for 
            ${
              numberOfParticipants === 1 ? "one person" : "more than one person"
            }`}
          sx={{ display: "block" }}
        />
        <span aria-hidden>{numberOfParticipants}</span>
      </div>
      <div sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
        <InvertableImage
          src={commentsIcon}
          alt={`${comments.length} Comments`}
          sx={{ display: "block" }}
        />
        <span aria-hidden>{comments.length}</span>
      </div>
      <div sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
        <InvertableImage
          src={likeIcon}
          alt={`${likes} Likes`}
          sx={{ display: "block" }}
        />
        <span aria-hidden>{likes}</span>
      </div>
    </div>
  </div>
);

const mapActivity = ({
  uid,
  title,
  summary,
  activityGroup,
  cardImage,
  likes,
  comments,
  numberOfParticipants,
  time,
}) => ({
  key: uid,
  title: title,
  body: summary,
  footerLeft: activityGroup.level,
  footerRight: activityGroup.uid,
  image: cardImage,
  linkTo: `/activities/${activityGroup.uid}/${uid}`,
  color: ColorMap[activityGroup.level],
  likes,
  comments,
  numberOfParticipants,
  time,
  footerComponent: (
    <CardFooter
      comments={comments}
      likes={likes}
      time={time}
      numberOfParticipants={numberOfParticipants}
    />
  ),
});

const mapActivityGroup = ({
  uid,
  level,
  name,
  description,
  image,
  numberOfActivities,
}) => ({
  key: uid,
  title: name,
  body: description,
  linkTo: `/activities/${uid}`,
  footerLeft: `${numberOfActivities} ${
    numberOfActivities === 1 ? "activity" : "activities"
  }`,
  image,
  color: ColorMap[level],
  backgroundColor: ColorMap[`${level}Background`],
});

const mapHolisticSkill = ({ uid, name, description, image }) => ({
  key: uid,
  title: name,
  body: description,
  image,
  linkTo: `/learningthroughplay/${uid}`,
  color: ColorMap[uid],
  backgroundColor: ColorMap[uid],
});

const mapArticle = ({ uid, title, summary, cardImage, linkTo }) => ({
  key: uid,
  title: title,
  body: summary,
  image: cardImage,
  linkTo,
  color: ColorMap.article,
});

export { mapActivity, mapActivityGroup, mapHolisticSkill, mapArticle };
