import { Grid as ThemeUIGrid } from "theme-ui";
import React from "react";

const Grid = ({ children, columns = [1, 2, 3], gap = 3, ...rest }) => (
  <ThemeUIGrid columns={columns} gap={gap} {...rest}>
    {children}
  </ThemeUIGrid>
);

export default Grid;
