const ColorMap = {
  Braille: "lbbRed",
  "Pre-braille": "lbbBlue",
  BrailleBackground: "brailleCardBackground",
  "Pre-brailleBackground": "preBrailleCardBackground",
  article: "text",
  creative: "lbbRed",
  physical: "lbbBlue",
  social: "lbbGreen",
  cognitive: "lbbYellow",
  emotional: "lbbPurple",
};

export default ColorMap;
