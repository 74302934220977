/** @jsx jsx */
import { jsx, Themed } from "theme-ui";
import Layout from "../components/Layout";
import RichText from "../components/RichText";
import ActivityGroupSections from "../components/ActivityGroupSections";
import { DESKTOP_ARTICLE_MAX_WIDTH } from "../utils/Constants";

import { LotsOfActivities } from "../assets/images/brailleheaders";

const Activities = ({
  pageContext: {
    activitiesPage: { title, body, seo },
    activityGroups,
  },
}) => (
  <Layout
    title={seo.title || "Activities"}
    description={seo.description}
    brailleTextImage={LotsOfActivities}
  >
    <div
      sx={{
        maxWidth: [null, null, `${DESKTOP_ARTICLE_MAX_WIDTH}px`],
        marginBottom: "80px",
      }}
    >
      <Themed.h1 sx={{ marginBottom: "35px" }}>{title}</Themed.h1>
      <RichText render={body} />
    </div>
    <ActivityGroupSections activityGroups={activityGroups} />
  </Layout>
);

export default Activities;
