/** @jsx jsx */
import { jsx, Themed } from "theme-ui";
import { navigate, Link } from "gatsby";

const Card = ({
  image,
  title,
  body,
  footerComponent = null,
  footerLeft,
  footerRight,
  highlightComponent = null,
  linkTo,
  color = "text",
  backgroundColor = "transparent",
  TitleHeadingComponent = Themed.h3,
  style = {},
}) => (
  <div
    sx={{
      display: "flex",
      flexDirection: "column",
      border: "1px solid",
      borderColor: "gray",
      borderBottom: "10px solid",
      borderBottomColor: color,
      height: "100%",
      color: "text",
      boxShadow: "0px 6px 11px -4px rgba(0, 0, 0, 0.3)",
      transition: "all 100ms",
      ":hover": {
        boxShadow: "0px 6px 11px -4px rgba(0, 0, 0, 0)",
        transform: "translateY(5px)",
      },
      cursor: "pointer",
      ...style,
    }}
    /*
        This is an accessibility hack.
        Wrapping a lot of content inside an <a> leads to bad user experience
        for screen readers. So the actual link is below, but we want the
        whole card to be clickable.
      */
    onClick={() => navigate(linkTo)}
      /*
    tabIndex="0"
      */
    onKeyUp={ev => {
      if (ev.nativeEvent.key === "Enter") navigate(linkTo);
    }}
  >
    <div
      sx={{
        bg: backgroundColor,
        width: "100%",
        height: "200px",
        position: "relative",
      }}
    >
      <img
        src={image.url}
        alt={image.alt}
        sx={{
          display: "block",
          width: "100%",
          height: "100%",
          objectFit: "cover",
        }}
      />
      {highlightComponent}
    </div>
    <div sx={{ padding: "0 1rem 1rem", flex: 1 }}>
      <Link
        to={linkTo}
        sx={{
          textDecoration: "none",
          color: "inherit",
          ":visited": { color: "inherit" },
        }}
        onClick={ev => ev.stopPropagation()}
      >
        <TitleHeadingComponent
          sx={{ variant: "text.h4", marginTop: "30px", marginBottom: "10px" }}
        >
          {title}
        </TitleHeadingComponent>
      </Link>
      <Themed.p sx={{ margin: 0 }}>{body}</Themed.p>
    </div>
    <div
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        padding: "1rem",
      }}
    >
      <span>{footerLeft}</span>
      <span sx={{ "::first-letter": { textTransform: "capitalize" } }}>
        {footerRight}
      </span>
    </div>
    {footerComponent}
  </div>
);

export default Card;
